var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: !_vm.movingElement,
            movable: !_vm.canvas.isReadOnlyModel && !_vm.movingElement,
            resizable:
              !_vm.canvas.isReadOnlyModel &&
              !_vm.movingElement &&
              _vm.isEditElement,
            deletable: !_vm.canvas.isReadOnlyModel && _vm.isEditElement,
            connectable: "",
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.value.name,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "16",
              "font-color": _vm.fontColor,
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            removeShape: _vm.onRemoveShape,
            dblclick: _vm.openPanel,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            "update:label": function ($event) {
              return _vm.$set(_vm.value, "name", $event)
            },
          },
        },
        [
          _c("geometry-rect", {
            attrs: {
              _style: {
                "fill-r": 1,
                "fill-cx": 0.1,
                "fill-cy": 0.1,
                "stroke-width": 0,
                stroke: _vm.value.color ? _vm.value.color : "#F1A746",
                fill: _vm.value.color ? _vm.value.color : "#F1A746",
                "fill-opacity": 1,
                r: "1",
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.propertyPanel
        ? _c("sticky-model-panel", {
            attrs: {
              isReadOnly: !_vm.isEditElement,
              showError: _vm.showError,
              widthStyle: _vm.panelStyle,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }