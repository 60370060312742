var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("StickyModelCanvas", {
    key: _vm.componentKey,
    attrs: { "render-count": _vm.componentKey },
    on: {
      forceUpdateKey: function ($event) {
        return _vm.forceRerender()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }